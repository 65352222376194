<template>
	<div v-dompurify-html="html" />
</template>

<script>
export default {
	data () {
		return {
			html: ''
		}
	},
	created () {
		this.html = JSON.parse(localStorage.getItem('preview') || {})?.html
	}
}
</script>
